import React from 'react'
import ImageLogo2 from '../../images/app_icon1.png'
import ImageAppStore from '../../images/btn-download-app-store.svg'
import ImageGoogleStore from '../../images/btn-download-google-store.svg'

function DownloadAppStore() {
  window.open(process.env.APP_STORE, '_blank')
}

function DownloadGooglePlay() {
  window.open(process.env.GOOGLE_PLAY, '_blank')
}

const styleAppIcon = { borderRadius: 30 }

export default props => (
  <div className="download-apps-panel">
    <div className="download-apps-panel-left">
      <img src={ImageLogo2} alt="ImageLogo2" style={styleAppIcon} />
    </div>
    <div className="download-apps-panel-right">
      <p className="download-apps-panel-right__text-new">{props.locale.NEW}!</p>
      <h2 className="download-apps-panel-right__text-main">
        {props.locale.CREATE_JOIN_ANYTIME}!
      </h2>
      <div className="download-apps-panel-right__box-1">
        <span className="download-apps-panel-right__text-download">
          Download Invitree Mobile App
        </span>
        <div className="download-apps-panel-right__text-download-device">
          Download Invitree Mobile App
        </div>
        <img
          className="download-apps-panel-right__img-app-store"
          src={ImageAppStore}
          alt="ImageAppStore"
          onClick={DownloadAppStore}
        />
        <img
          className="download-apps-panel-right__img-google-store"
          src={ImageGoogleStore}
          alt="ImageGoogleStore"
          onClick={DownloadGooglePlay}
        />
      </div>
    </div>
  </div>
)
