import s, { keyframes } from 'styled-components'

const placeHolderShimmer = keyframes`
    0%{
        background: #f6f7f8;
    }
    50%{
        background: #dedede;
    }
    100%{
        background: #f6f7f8;
    }
`
export const ImageBanner = s.img`
    width: 100%;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
    min-height: 300px;
    @media (max-width: 750px) {
      min-height: 100px;
    }
`
