import React from 'react'

/* eslint-disable jsx-a11y/anchor-is-valid */
export default class Panel extends React.Component {
  static defaultProps = {
    hideBG: true,
    underlineColor: '#C14C40',
  }

  constructor(props) {
    super(props)
    this.state = {
      style: null,
    }
  }

  componentDidMount() {
    const w = document.documentElement.clientWidth
    const bgColor = this.props.hideBG ? null : { backgroundColor: '#E8E8E8' }
    if (w <= 450) {
      this.setState({ style: { padding: '20px 10px', ...bgColor } })
    } else {
      this.setState({ style: { padding: '20px 0', ...bgColor } })
    }
  }

  styleFont1 = { fontFamily: `'Noto Serif', serif` }

  display(props) {
    return (
      <div className="row no-gutters" style={this.state.style}>
        <div style={{ width: '100%' }}>
          <div className="card__header" style={{ float: 'left' }}>
            <h3 style={this.styleFont1}>{props.title}</h3>
            <div
              className="underline"
              style={{
                borderBottom: `3px solid ${this.props.underlineColor}`,
                marginBottom: 14,
              }}
            />
          </div>
          {props.onSeeAll && (
            <div className="card__header__seeall">
              <a onClick={props.onSeeAll}>
                {props.locale.SELL_ALL || 'See all'}
              </a>
            </div>
          )}
        </div>
        {props.children}
      </div>
    )
  }

  render() {
    return this.display(this.props)
  }
}
