import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import Cookies from 'js-cookie'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Loading from '../components/loading'
import ShareModal from '../components/popup/share'
import View from '../components/presentation/home'
import { BoxGray } from '../components/template/box'
import { getProfileFromId, getProfile } from '../utils/api'
import moment from 'moment'
import authActions from '../state/auth/actions'
import homeActions from '../state/home/actions'
import clubRegisterActions from '../state/club-register/actions'
class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPageReady: false,
    }
    this.onGoUpcoming = this.onGoUpcoming.bind(this)
    this.onGoJoined = this.onGoJoined.bind(this)
    this.onGoHistory = this.onGoHistory.bind(this)
    this.onGoPublic = this.onGoPublic.bind(this)
  }

  componentDidMount() {
    this.props.checkAuthorization(null, '/', null, {
      onSuccess: () => {
        const eventID = Cookies.get('TEMP_EVENT_ID')
        if (eventID) {
          Cookies.remove('TEMP_EVENT_ID')
          navigate('/event-detail/?id=' + eventID)
        } else {
          this.setState({ isPageReady: true }, () => {
            this.props.onLoad()
          })
        }
      },
    })
    this.handleCheckProfile()
  }
  handleCheckProfile = async () => {
    if (!Cookies.get('_id')) {
      const resData = await getProfile()
      if (resData.data && resData.data.username) {
        const userData = await getProfileFromId(resData.data.username)
        if (userData && userData.data && userData.data._id) {
          Cookies.set('_id', userData.data._id, {
            path: '/',
            expires: new Date(
              moment()
                .add(1, 'day')
                .format()
            ),
          })
          this.props.onSetProfile({
            ...userData.data,
          })
        }
      }
    }
  }
  onGoUpcoming() {
    navigate(`/upcoming`)
  }

  onGoJoined() {
    navigate(`/joined`)
  }

  onGoHistory() {
    navigate(`/history`)
  }

  onGoPublic() {
    navigate(`/public-list`)
  }

  reArrayCardData(dataList) {
    const newList = []
    if (dataList.length > 0) {
      dataList.forEach(v => {
        newList.push(...v)
      })
    }
    return newList
  }

  render() {
    if (!this.props.state.readyPageLogin) {
      return <Loading />
    }
    if (!this.state.isPageReady) {
      return <Loading />
    }
    return (
      <Layout auth={this.props.auth.currentUser !== null}>
        <SEO keywords={[`invitree`]} />
        <BoxGray>
          <View
            onGoUpcoming={this.onGoUpcoming}
            onGoJoined={this.onGoJoined}
            onGoHistory={this.onGoHistory}
            onGoPublic={this.onGoPublic}
            onShare={this.props.onShare}
            onGoDetail={this.props.onGoDetail}
            data={{
              slider_show: this.props.state.slider_show,
              upcoming_list: this.props.state.listUpcoming,
              upcoming_list_mobile: this.reArrayCardData(
                this.props.state.listUpcoming
              ),
              joined_list: this.props.state.listJoined,
              joined_list_mobile: this.reArrayCardData(
                this.props.state.listJoined
              ),
              history_list: this.props.state.listHistory,
              history_list_mobile: this.reArrayCardData(
                this.props.state.listHistory
              ),
              public_list: this.props.state.listPublic,
              public_list_mobile: this.reArrayCardData(
                this.props.state.listPublic
              ),
            }}
            locale={this.props.app.locale[this.props.app.lang]}
          />
        </BoxGray>
        <ShareModal
          id={this.props.state.selectedShareID}
          locale={this.props.app.locale[this.props.app.lang]}
        />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onLoad: homeActions.onLoadHomeLogin,
      onShare: homeActions.onShare,
      onGoDetail: homeActions.onGoDetail,
      onSetProfile: clubRegisterActions.onSetProfile,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage)
