import React from 'react'

import Carousel from '../carousel'
import { ImageBanner } from '../banner'
import CardCarousel from '../card/card-carousel'
import CardPanel from '../card/panel'
import DownloadAppsPanel from '../download-apps-panel/download-apps-panel'
import { BoxDetail, BoxWrapper } from '../box'

const style1 = { height: 10 }

export default props => (
  <BoxWrapper>
    {props.data.slider_show.length > 0 && (
      <Carousel showEveryTime className="is-banner-slider">
        {props.data.slider_show.map(
          (v, i) =>
            v.img_url && (
              <div key={`SliderShow-item-${i}`}>
                <ImageBanner src={v.img_url} alt="" />
              </div>
            )
        )}
      </Carousel>
    )}

    <div style={style1} />
    {props.data.upcoming_list.length > 0 && (
      <BoxDetail>
        <CardPanel
          title={props.locale.UPCOMING_EVENT}
          onSeeAll={props.onGoUpcoming}
          locale={props.locale}
        >
          <CardCarousel
            className="is-card-slider"
            data={props.data.upcoming_list}
            dataMobile={props.data.upcoming_list_mobile}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        </CardPanel>
      </BoxDetail>
    )}

    {props.data.joined_list.length > 0 && (
      <BoxDetail>
        <CardPanel
          title={props.locale.JOINED}
          onSeeAll={props.onGoJoined}
          locale={props.locale}
        >
          <CardCarousel
            className="is-card-slider"
            data={props.data.joined_list}
            dataMobile={props.data.joined_list_mobile}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        </CardPanel>
      </BoxDetail>
    )}

    {props.data.history_list.length > 0 && (
      <BoxDetail>
        <CardPanel
          title={props.locale.HISTORY}
          onSeeAll={props.onGoHistory}
          locale={props.locale}
        >
          <CardCarousel
            className="is-card-slider"
            data={props.data.history_list}
            dataMobile={props.data.history_list_mobile}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        </CardPanel>
      </BoxDetail>
    )}

    {props.data.public_list.length > 0 && (
      <BoxDetail>
        <CardPanel
          title={props.locale.PUBLIC}
          onSeeAll={props.onGoPublic}
          locale={props.locale}
        >
          <CardCarousel
            className="is-card-slider"
            data={props.data.public_list}
            dataMobile={props.data.public_list_mobile}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        </CardPanel>
      </BoxDetail>
    )}

    <DownloadAppsPanel locale={props.locale} />
  </BoxWrapper>
)
