import React from 'react'
import s from 'styled-components'
import { getShowDate } from '../../utils/string'
import { getLanguage } from '../../locale'

const ImageLogo = s.img`
    z-index: 1 !important;
    margin: -50px auto 0 !important;
    width: 90px !important;
    height: 87px !important;
    border-radius: 60px;
    box-shadow: 0 5px 7px rgba(0,0,0,.22);
`
const style1 = { marginRight: 7 }
const style2 = { width: '100%' }

export const CardComponent = props => (
  <ul className="cards">
    {props.data.map((v, i) => (
      <li
        className={props.clubStyle == true ? "cards__item__club" : "cards__item"}
        key={`cards__item-${i}`}
        style={props.isSignle ? style2 : null}
      >
        <div className="card">
          <div
            className="card__image"
            style={{ backgroundImage: `url(${v.img_url})` }}
          />
          <ImageLogo src={v.logo_img} alt="" />
          <div className="card__content">
            <div className="card__title">{v.title}</div>
            <p className="card__time">
              {getShowDate(getLanguage().lang, v.time)}
            </p>
            {/* <p className="card__text">{v.text}</p> */}
            <div className="card__content__footer">
              <div
                className="card__content__footer__left"
                onClick={() => props.onShare(v.id)}
              >
                <i
                  className={`fa ${
                    props.isMyEvents ? 'fa-edit' : 'fa-share-alt'
                  }`}
                  style={style1}
                />{' '}
                {props.isMyEvents ? props.locale.EDIT : props.locale.SHARE}
              </div>
              <div
                className="card__content__footer__right"
                onClick={() => props.onGoDetail(v.id, v)}
              >
                {' '}
                {props.isMyEvents
                  ? props.locale.MANAGE
                  : props.locale.MORE_DETAIL}
              </div>
            </div>
          </div>
        </div>
      </li>
    ))}
  </ul>
)
