import React from 'react'
import { CardComponent } from './card-component'
import Carousel from '../carousel'

export default class CardCarousel extends React.Component {
  display(props) {
    const ele = []
    if (props.data.length > 0) {
      props.data.forEach((v, i) => {
        ele.push(
          <CardComponent
            key={`card-${i}`}
            data={v}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
            clubStyle={props.clubStyle}
          />
        )
      })
    }

    const ele2 = []

    if (props.dataMobile.length > 0) {
      props.dataMobile.forEach((v, i) => {
        ele2.push(
          <CardComponent
            key={`card-${i}`}
            data={[v]}
            isSignle
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        )
      })
    }

    return (
      <React.Fragment>
        <Carousel
          className={this.props.className}
          loop={false}
          autoplay={false}
        >
          {ele}
        </Carousel>
        <Carousel
          className={this.props.className}
          loop={false}
          autoplay={false}
          isMobile
        >
          {ele2}
        </Carousel>
      </React.Fragment>
    )
  }

  render() {
    return this.display(this.props)
  }
}
