import React from 'react'

/* eslint-disable no-undef */
export default class extends React.Component {
  static defaultProps = {
    items: 1,
    loop: true,
    autoplay: true,
    showEveryTime: false,
    className: '',
  }

  constructor(props) {
    super(props)
    this.resize = this.resize.bind(this)
  }

  componentDidMount() {
    this.display()
    window.addEventListener('resize', this.resize)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.display()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize() {
    this.owl.trigger('refresh.owl.carousel')
  }

  display() {
    this.owl = $(this.$el).owlCarousel({
      loop: this.props.loop,
      margin: 10,
      items: 1,
      autoHeight: true,
      autoplay: this.props.autoplay,
      smartSpeed: 1500,
      autoplayTimeout: 6000,
      nav: true,
      navText: [
        '<i class="fa fa-chevron-left"/>',
        '<i class="fa fa-chevron-right"/>',
      ],
    })
    setTimeout(this.resize, 500)
  }

  render() {
    let isMobileClass = this.props.isMobile ? 'is-mobile' : 'is-pc'
    if (this.props.showEveryTime) {
      isMobileClass = ''
    }
    return (
      <div
        className={`owl-carousel owl-theme ${isMobileClass} ${
          this.props.className
        }`}
        ref={ref => (this.$el = ref)}
      >
        {this.props.children}
      </div>
    )
  }
}
